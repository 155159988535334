//@ts-check
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { Link, navigate } from "gatsby";

import API from "../../utils/API";
import { Button, Input, Modal } from "antd";
import "./myAccountDetails.css";

const MyAccountDetails = () => {
  const authContext = useContext(AuthContext);
  const [userDetails, setUserDetails] = useState({
    emailAddress: "",
    membershipLevel: "free",
    requestsRemaining: "",
    autoRenew: true,
    currentSubscriptionEnd: Date.now() / 1000,
    stripeId: "",

    userId: "",
    referralBonusUsage: 0,
    referralsToNextBonus: 3,
  });

  const [referralModalOpen, setReferralModalOpen] = useState(false);

  const getUser = async () => {
    const user = await API.get({ path: "mydetails" });

    setUserDetails(user);
  };

  useEffect(() => {
    getUser();
  }, []);

  if (authContext.isLoggedIn === false) {
    navigate("/profile-authentication");
  }

  const logout = async () => {
    await authContext.signOut();
    setTimeout(() => {
      authContext.assessLoggedInState();
    }, 50);
  };

  return (
    <section className="services-details-area ptb-100">
      <Modal
        title="Referral Program"
        open={referralModalOpen}
        onOk={() => setReferralModalOpen(false)}
        onCancel={() => setReferralModalOpen(false)}
        className="referral-modal"
      >
        <p>We want to help more people to boost their LI growth</p>
        <p>And we want to reward you for helping us make that happen</p>
        <p>
          So for every 3 users who sign up with your link, you get to generate
          an extra comment every day
        </p>

        <hr />

        {userDetails?.referralBonusUsage > 0 && (
          <p>
            You have earnt{" "}
            <b>{userDetails.referralBonusUsage} extra comments</b>
          </p>
        )}

        <p>
          You'll get an extra daily comment if you sign up{" "}
          <b>{userDetails.referralsToNextBonus} more users</b>
        </p>

        <div style={{ display: "flex" }}>
          <Input
            value={`https://linkedinpowertools.com/?referral=${userDetails.userId}`}
            disabled
          />
          <Button
            style={{ width: 80 }}
            type="primary"
            onClick={() =>
              navigator.clipboard.writeText(
                `https://linkedinpowertools.com/?referral=${userDetails.userId}`
              )
            }
          >
            Copy
          </Button>
        </div>
      </Modal>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="account-details">
              <h3>Account Details</h3>
              <p>Email Address: {userDetails.emailAddress}</p>
              <p>Membership Level: {userDetails.membershipLevel}</p>
              <p>
                Membership {userDetails.autoRenew ? "Renews" : "Ends"} on:{" "}
                {userDetails.currentSubscriptionEnd
                  ? new Date(
                      userDetails.currentSubscriptionEnd * 1000
                    ).toDateString()
                  : "Free Forever"}
              </p>
              <p>Comment Credits Remaining: {userDetails.requestsRemaining}</p>

              <Link
                to="https://chrome.google.com/webstore/detail/linkedin-powertools/ebbpbbdigdenlglbdlpnbbonofdaahlf"
                className="default-btn"
              >
                <i className="flaticon-tick"></i>
                Download Chrome Extension Here
                <span></span>
              </Link>

              <h3>Settings</h3>

              <div className="row">
                <div className="col-lg-4 col-sm-6 col-md-6 " onClick={logout}>
                  <div
                    className="single-industries-serve-box"
                    style={{ cursor: "pointer" }}
                  >
                    <div className="icon">
                      <i className="flaticon-web"></i>
                    </div>
                    Log Out
                  </div>
                </div>
                {userDetails.autoRenew === false ? (
                  <div className="col-lg-4 col-sm-6 col-md-6">
                    <Link to={process.env.STRIPE_CUSTOMER_PORTAL || ""}>
                      <div className="single-industries-serve-box">
                        <div className="icon">
                          <i className="flaticon-investment"></i>
                        </div>
                        Renew Membership
                      </div>
                    </Link>
                  </div>
                ) : userDetails.membershipLevel === "free" ? (
                  <div className="col-lg-4 col-sm-6 col-md-6">
                    <Link to="/memberships">
                      <div className="single-industries-serve-box">
                        <div className="icon">
                          <i className="flaticon-investment"></i>
                        </div>
                        Upgrade Membership Level
                      </div>
                    </Link>
                  </div>
                ) : (
                  <div className="col-lg-4 col-sm-6 col-md-6">
                    <div
                      className="single-industries-serve-box"
                      onClick={() => {
                        alert(
                          "Please contact me directly at sam@completecoding.io to change from a paid plan"
                        );
                      }}
                    >
                      <div className="icon">
                        <i className="flaticon-investment"></i>
                      </div>
                      Upgrade Or Change Membership Level
                    </div>
                  </div>
                )}
                {userDetails.membershipLevel != "free" && (
                  <div className="col-lg-4 col-sm-6 col-md-6">
                    <Link to={process.env.STRIPE_CUSTOMER_PORTAL || ""}>
                      <div className="single-industries-serve-box">
                        <div className="icon">
                          <i className="flaticon-investment"></i>
                        </div>
                        Manage Billing (invoices and payment methods)
                      </div>
                    </Link>
                  </div>
                )}

                {userDetails.membershipLevel != "free" &&
                  userDetails.autoRenew && (
                    <div className="col-lg-4 col-sm-6 col-md-6">
                      <Link to={process.env.STRIPE_CUSTOMER_PORTAL || ""}>
                        <div className="single-industries-serve-box">
                          <div className="icon">
                            <i className="flaticon-investment"></i>
                          </div>
                          Cancel Membership
                        </div>
                      </Link>
                    </div>
                  )}

                <div className="col-lg-4 col-sm-6 col-md-6">
                  <Link to="/reset-password">
                    <div className="single-industries-serve-box">
                      <div className="icon">
                        <i className="flaticon-lock"></i>
                      </div>
                      Reset Password
                    </div>
                  </Link>
                </div>

                <div
                  className="col-lg-4 col-sm-6 col-md-6"
                  onClick={() => {
                    setReferralModalOpen(true);
                  }}
                >
                  <div className="single-industries-serve-box">
                    <div className="icon">
                      <i className="flaticon-user"></i>
                    </div>
                    Referral Program
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MyAccountDetails;
